import { createApp, Transition, TransitionGroup } from 'vue';
import mitt from 'mitt';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { vue3Debounce } from 'vue-debounce';
// @ts-ignore
import VueClipboard from 'vue3-clipboard'
import * as labs from 'vuetify/labs/components';
import 'material-design-icons/iconfont/material-icons.css';

import {
  createVuesticEssential,
  VaSelect,
  VaDateInput,
  VaInput,
  VaButton,
} from 'vuestic-ui';

import Toaster from '@incuca/vue3-toaster';
import InlineSvgPlugin from 'vue-inline-svg';
import FloatingVue from 'floating-vue';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Antd from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import './assets/styles/fonts.scss';
import './assets/styles/main.scss';
import './assets/styles/variables.scss';
import 'ant-design-vue/dist/antd.css';
import 'floating-vue/dist/style.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const pinia = createPinia();

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#000000',
    secondary: '#FFFFFF',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const vuetify = createVuetify({
  directives: {
    ...directives,
    debounce: vue3Debounce({ lock: true }),
  },
  components: {
    ...components,
    ...labs,
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const emitter = mitt();
const vueApp = createApp(App);
vueApp.config.globalProperties.emitter = emitter;

Sentry.init({
  app: vueApp,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'new.my.24ttl.stream', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

vueApp.use(router)
  .use(vuetify)
  .use(pinia)
  .use(Toaster)
  .use(Antd)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(
    createVuesticEssential({
      components: {
        VaSelect,
        VaDateInput,
        VaInput,
        VaButton,
      },
      config: {
        components: {
        },
        colors: {
          variables: {
            'background-element': '#f6f6f6',
            primary: '#000000',
          },
        },
      },
    }),
  )
  .use(FloatingVue, {
    componentName: 'Floating',
  })
  .use(InlineSvgPlugin.InlineSvgPlugin)
  .component('inline-svg', InlineSvgPlugin)
  .component('FloatingTooltip', vueApp.component('VTooltip')!)
  .component('FloatingMenu', vueApp.component('VMenu')!)
  .component('tree-table', TreeTable)
  .component('tree-column', Column)
  .component('transition', Transition)
  .component('transition-group', TransitionGroup)
  .mount('#app');
