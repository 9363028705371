const getUniqArrayOfObjects = (arrayOfObject: any[], array:any[] = [], key = 'id'):any[] => {
  arrayOfObject.forEach((item) => {
    const findExist = array.find((uniqItem) => uniqItem[key] === item[key]);
    if (!findExist) {
      array.push(item);
    }
  });

  return array;
};
const sortAsc = (items: any[], field?: string): any[] => items.slice().sort((a, b) => {
  if (field) {
    const compareA = a?.[field] || a;
    const compareB = b?.[field] || b;
    return compareA.localeCompare(compareB);
  }
  return a.localeCompare(b);
});
const sortArrayOfObject = (array: any, sortKey = 'name'):any[] => {
  const sortArray: any[] = [];
  let keys: string[] = [];

  array.forEach((obj:any) => {
    keys.push(obj[sortKey]);
  });

  keys = sortAsc(keys);

  keys.forEach((key) => {
    const findElm = array.find((info: any) => info[sortKey] === key);
    sortArray.push(findElm);
  });

  return sortArray;
};
const listToTree = (array: any[]):any[] => {
  array?.forEach((i) => {
    i.label = i.name;
    i.children = array?.filter((j) => j.parentId === i.id);
    if (!i.children?.length) {
      delete i.children;
    }
  });

  const parentIds = array?.map((i) => i.id);
  return JSON.parse(JSON.stringify(array?.filter((i) => !parentIds?.includes(i.parentId))));
};
export {
  sortAsc,
  sortArrayOfObject,
  getUniqArrayOfObjects,
  listToTree,
};
