import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {useAuthStore} from "@/store/auth";
// import {defineComponent} from "vue";

// const EmptyParentComponent = defineComponent({
//   setup() {
//     return () => (
//       <router-view/>
//     )
//   }
// });

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    name: "Auth",
    meta: {
      isGlobalPage: true,
    },
    component: () => import("@/templates/TemplateAuth/index.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        meta: {
          isGlobalPage: true,
        },
        component: () => import("@/pages/auth/PageLogin/index.vue"),
      },
    ],
  },
  {
    path: "/panel",
    name: "Panel",
    component: () => import("@/templates/TemplateApp/index.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/pages/panel/PageDashboard/index.vue"),
        meta: {
          requiredPermission: "User",
        },
      },
      {
        path: "insights",
        name: "insights",
        component: () => import("@/pages/panel/PageInsights/index.vue"),
        meta: {
          requiredPermission: "User",
        },
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("@/pages/panel/PageReports/index.vue"),
        meta: {
          requiredPermission: "User",
        },
      },
      {
        name: "statistics",
        path: "statistics",
        children: [
          {
            name: "liveMonitor",
            path: "liveMonitor",
            meta: {
              requiredPermission: "Live_monitor",
            },
            component: () => import("@/pages/panel/PageLiveMonitor/index.vue"),
          },
          {
            name: "impressions",
            path: "impressions",
            meta: {
              requiredPermission: "User",
            },
            component: () => import("@/pages/panel/PageInsights/index.vue"),
          },
          {
            name: "products",
            path: "products",
            meta: {
              requiredPermission: "User",
            },
            component: () => import("@/pages/panel/PageInsights/index.vue"),
          },
        ],
      },
      {
        name: "trends",
        path: "trends",
        meta: {
          requiredPermission: "Trends",
        },
        component: () => import("@/pages/panel/PageTrends/index.vue"),
      },
      {
        name: "profile",
        path: "profile",
        meta: {
          requiredPermission: "User",
        },
        component: () => import("@/pages/panel/PageProfile/index.vue"),
      },
      {
        name: "content-manager",
        path: "content-manager",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageContentManager/index.vue"),
      },
      {
        name: "retail-manager",
        path: "retail-manager",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageRetailManager/index.vue"),
      },
      {
        name: "users",
        path: "users",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUsers/index.vue"),
      },
      {
        name: "usersAdd",
        path: "users/add",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUsers/Editor/index.vue"),
      },
      {
        name: "usersEdit",
        path: "users/edit/:id",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUsers/Editor/index.vue"),
      },
      {
        name: "groups",
        path: "groups",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUserGroups/index.vue"),
      },
      {
        name: "groupsAdd",
        path: "groups/add",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUserGroups/Editor/index.vue"),
      },
      {
        name: "groupsEdits",
        path: "groups/edit/:id",
        meta: {
          requiredPermission: "Admin",
        },
        component: () => import("@/pages/panel/PageUserGroups/Editor/index.vue"),
      },
      {
        name: "builderList",
        path: "builder",
        component: () => import("@/pages/panel/builder/index.vue"),
        meta: {
          requiredPermission: "Builder",
        },
      },
      {
        name: "builderEdit",
        path: "builder/:id",
        component: () => import("@/pages/panel/builder/PageBuilderEdit/index.vue"),
        meta: {
          requiredPermission: "Builder",
        },
      },
      {
        name: "builder-pro-epacks",
        path: "builder-pro",
        meta: {
          requiredPermission: "BuilderPro",
        },
        component: () => import("@/pages/panel/builder-pro/epacks/index.vue"),
      },
    ],
  },
  {
    name: 'builder-pro-preview',
    path: '/panel/builder-pro/preview',
    meta: {
      globalPage: true,
      noAuthRequired: true
    },
    component: () => import("@/pages/panel/builder-pro/preview/index.vue"),
  },
  {
    name: "builder-pro-editor",
    path: "/panel/builder-pro/:id",
    meta: {
      requiredPermission: "BuilderPro",
    },
    component: () => import("@/pages/panel/builder-pro/editor/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.name) {
    next({name: "dashboard"});
  }
  if (!to.meta.isGlobalPage && !to.meta.noAuthRequired && !useAuthStore().profile) {
    if (localStorage.getItem("accessToken")) {
      useAuthStore()
        .getUserProfile()
        .then(() => {
          if (((to.meta.requiredPermission === "Admin" && !useAuthStore().profile?.roles.includes("ROLE_ADMIN")) &&
            (![...(useAuthStore().profile?.permissions?.map((p) => p.name) || []), 'User'].includes(to.meta.requiredPermission as string)))
          ) {
            next({name: "dashboard"});
          } else {
            next();
          }
        })
        .catch(() => {
          next({name: "Login"});
        });
    } else {
      next({name: "Login"});
    }
  } else if (!to.meta.isGlobalPage && useAuthStore().profile && ((to.meta.requiredPermission === "Admin" && !useAuthStore().profile?.roles.includes("ROLE_ADMIN")) &&
    (![...(useAuthStore().profile?.permissions?.map((p) => p.name) || []), 'User'].includes(to.meta.requiredPermission as string)))
  ) {
    next({name: "dashboard"});
  } else {
    next();
  }
});
export default router;
